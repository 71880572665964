/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import url("https://fonts.googleapis.com/css2?family=Oleo+Script+Swash+Caps&family=Quicksand:wght@500&family=Signika+Negative&display=swap");

* {
  font-family: "Oleo Script Swash Caps", cursive;
  font-family: "Quicksand", sans-serif;
  font-family: "Signika Negative", sans-serif;
}

body {
  --background-color: var(--ion-color-primary);
}

.container {
  // ion-content {
  //   // --background: #e9e9e9;
  // }
  min-height: 100vh;
}

.no-scroll {
  --overflow: hidden;
}

.desktop {
  margin-top: 65px;
}

.mobile {
  margin-top: 40px;
}

a:hover {
  color: rgb(119, 197, 119);
}

.modal-popup {
  --border-radius: 5px;
  --min-height: 400px;
  --min-width: 200px;
}

.modal-popup-mobile {
  --border-radius: 0px;
  --height: 100%;
  --min-width: 100%;
  --padding-start: 0px;
  --ion-background-color: #343b46;
}

.modal-popup-policy {
  --border-radius: 0px;
  --height: 100vh;
  --min-width: 100%;
  --padding-start: 0px;
  --ion-background-color: #343b46;
}

.modal-popup-login {
  --border-radius: 20px;
  --height: 600px;
  --width: 400px;
  --padding-start: 0px;
  --ion-background-color: #343b46;
}

.modal-popup-notification {
  --border-radius: 20px;
  --height: 300px;
  --width: 400px;
  --padding-start: 0px;
  --ion-background-color: #343b46;
}

.modal-popup-notification-mobile {
  --border-radius: 20px;
  --height: 100%;
  --width: 100%;
  --padding-start: 0px;
  --ion-background-color: #343b46;
}

.modal-popup-form {
  --border-radius: 20px;
  --height: 500px;
  --width: 400px;
  --padding-start: 0px;
  --ion-background-color: #343b46;
}

.error {
  color: var(--ion-color-danger);
  font-weight: bold;
  font-size: 12px;
}

.primary-button {
  background-color: #69a507;
  color: #ffffff;
  border-radius: 25px;
  font-size: 15px;
  font-weight: bold;
  padding: 10px;
  padding-right: 30px;
  padding-left: 30px;
}

.primary-input {
  background: rgb(255, 255, 255);
  --padding-start: 10px !important;
  color: #000000;
  border-radius: 5px;
  margin-bottom: 10px;
}

.filter-modal {
  --border-radius: 20px;
  --height: 330px;
  --width: 300px;
  --ion-background-color: #43546e;
}

.badge-notification {
  height: 18px;
  margin: auto;
}

.checkout-page {
  --border-radius: 20px;
  --height: 450px;
  --width: 400px;
  --ion-background-color: rgb(250, 250, 250);
}

.payout-approval-page {
  --border-radius: 20px;
  --height: 320px;
  --width: 350px;
  --ion-background-color: var(--ion-color-primary);
}

.ticket-page {
  --border-radius: 20px;
  --height: 500px;
  --min-height: 500px;
  --width: 400px;
  --ion-background-color: rgb(250, 250, 250);
}

.forgot-password-mobile {
  --border-radius: 0px;
  --height: 100%;
  --min-width: 100%;
  --padding-start: 0px;
  --ion-background-color: #343b46;
}

.forgot-password {
  --border-radius: 20px;
  --height: 300px;
  --width: 400px;
  --padding-start: 0px;
  --ion-background-color: #343b46;
}

.change-password {
  --border-radius: 20px;
  --height: 450px;
  --width: 400px;
  --padding-start: 0px;
  --ion-background-color: #343b46;
}

.otp-verify {
  --border-radius: 20px;
  --height: 450px;
  --width: 400px;
  --padding-start: 0px;
  --ion-background-color: #343b46;
}

.subscription-plan-modal {
  --border-radius: 20px;
  --height: 300px;
  --width: 400px;
  --padding-start: 0px;
}

ion-alert.custom-alert {
  --backdrop-opacity: 0.7;
}

.custom-alert .alert-button-group {
  padding: 8px;
}

button.alert-button.alert-button-confirm {
  background-color: var(--ion-color-danger);
  color: var(--ion-color-success-contrast);
}

.md button.alert-button.alert-button-confirm {
  border-radius: 4px;
}

.ios .custom-alert button.alert-button {
  border: 0.55px solid rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.2);
}

.ios button.alert-button.alert-button-cancel {
  border-right: 0;
  border-bottom-left-radius: 13px;
  border-top-left-radius: 13px;
}

.ios button.alert-button.alert-button-confirm {
  border-bottom-right-radius: 13px;
  border-top-right-radius: 13px;
}

.profile-photo-container {
  color: white;
  height: 315px;
  width: 360px;
  margin: auto;
  --ion-background-color: rgb(54, 51, 51);
}

@media only screen and (max-width: 400px) {
  .profile-photo-container {
    color: white;
    height: 300px;
    width: 300px;
    margin: auto;
    --ion-background-color: rgb(54, 51, 51);
  }
}

@media only screen and (min-width: 220px) and (max-width: 320px) {
  .profile-photo-container {
    color: white;
    height: 300px;
    width: 220px;
    margin: auto;
    --ion-background-color: rgb(54, 51, 51);
  }
}
